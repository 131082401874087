import React from 'react';
import {
  Bolt
} from "@mui/icons-material"

const pulses = [
  {
    icon: '🍽️',
    title: 'New 5 star restaurant open',
    description: 'When a nice new restaurant opens up nearby, Pulse will recommend it to a person.',
  },
  {
    icon: '📰',
    title: 'Industry News',
    description: 'When there’s big industry news happening such as legislation, acquisition, etc.',
  },
];

const PulseCard = ({ icon, title, description }) => (
  <div
    className="relative p-6 bg-white shadow-lg rounded-lg cursor-pointer"
  >
    <div className="flex items-center mb-4">
      <div className="relative flex items-center justify-center w-12 h-12 rounded-full">
        {icon}
      </div>
      <h2 className="ml-3 text-xl font-semibold">{title}</h2>
      <Bolt className="absolute top-0 right-0 mt-2 mr-2 text-blue-500"/>
    </div>
    <div className="text-gray-600 p-2 border-t">{description}</div>
  </div>
);

const PulseComponent = () => {
  return (
    <div className="flex flex-col items-center space-y-8">
      <div className="flex space-x-4 w-2/3 mt-4">
        {pulses.map((pulse, index) => (
          <PulseCard key={index} {...pulse} />
        ))}
      </div>
    </div>
  );
};

export default PulseComponent;
