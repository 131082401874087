import React from 'react'
import { useDispatch } from 'react-redux';
import { actions } from 'users/ducks';
import { Google } from '@mui/icons-material';

const stepFour = () => {
  const redirectUri = process.env.REACT_APP_SEND_URI_PROD;
  const dispatch = useDispatch();

  const handleConnectEmail = () => {
    // Dispatch the update onboarding action
    dispatch(actions.updateOnboarding());
    window.location.href = `https://accounts.google.com/o/oauth2/auth?response_type=code&client_id=306269071464-nl9fsl63a4el8qh1sdh9m3f3lrhk7d08.apps.googleusercontent.com&redirect_uri=${redirectUri}&scope=https://www.googleapis.com/auth/gmail.send+https://www.googleapis.com/auth/userinfo.email+https://www.googleapis.com/auth/userinfo.profile&state=tbb3cU7uAdS0BXVmnWQKCZ1L4Xr0fl&access_type=offline`;
  };

  return (
    <div className='flex items-center'>
      <p className='font-semibold text-sm'>Note: Please select the same email you registered with</p>
      <button
        onClick={handleConnectEmail}
        className="flex items-center mt-6 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
      >
        <Google className="mr-2" />
        Connect an email to send pulses
      </button>
    </div>
  )
}

export default stepFour
