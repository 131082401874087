import React, { useRef, useEffect } from 'react';
import maplibregl from 'maplibre-gl';
import usGeoJson from './usGeo.json';
import Loader from '../loader/index.js'
import 'maplibre-gl/dist/maplibre-gl.css';

// TODO clean style tags out
// TODO: Include a

// later
// TODO: Make heatmap points clickable, accompanying UI with click
// TODO: Heatmap icons should vary in color based on status from JSON (lead, customer, not interested, etc.), this will need to be filterable as well
// TODO: Parse Alaska, Hawaii, and Puerto Rico seperatley, underneath existing map
// This is why we are using React Ref hook, mapLibre expects container when creating new map instance


const UsMap = ({ leads, shouldResetMap, fromPath }) => {
  const mapContainer = useRef(null);
  const mapRef = useRef(null)

  const getBoundsFromCoordinates = (geometry) => {
    const bounds = new maplibregl.LngLatBounds();
  
    if (geometry.type === "Polygon") {
      geometry.coordinates.forEach(polygon => {
        polygon.forEach(coord => {
          bounds.extend(coord);
        });
      });
    } else if (geometry.type === "MultiPolygon") {
      geometry.coordinates.forEach(multipolygon => {
        multipolygon.forEach(polygon => {
          polygon.forEach(coord => {
            bounds.extend(coord);
          });
        });
      });
    }
    return bounds;
  };

  useEffect(() => {
    const initializeMap = () => {
      if (!leads || !leads.leads || leads.leads.length === 0) {
        return <Loader />;
      }
      const mapOptions = {
        container: mapContainer.current,
        style: {
          version: 8,
          sources: {},
          layers: [],
        },
        center: [-95, 38], // Default center for USA
        zoom: 3.5,
      };

      if (!mapRef.current) {
        let mapData = usGeoJson; 
      
        if (leads?.geo_id == null) {
          console.warn("geo_id is not present in leads");
        } else {
          const GEO_ID = leads.geo_id;
          if (GEO_ID !== "USA") {
            const stateFeature = usGeoJson.features.find(feature => feature.properties.GEO_ID === GEO_ID);
            if (stateFeature && stateFeature.geometry && stateFeature.geometry.coordinates) {
              const bounds = getBoundsFromCoordinates(stateFeature.geometry);
              mapOptions.bounds = bounds;
              mapOptions.fitBoundsOptions = { padding: 100 };
              mapData = {
                type: 'FeatureCollection',
                features: [stateFeature]
              };
            }
          }
        }        
      
        mapRef.current = new maplibregl.Map(mapOptions);
      
        mapRef.current.on('load', () => {
          mapRef.current.addSource('states', {
            type: 'geojson',
            data: mapData, 
          });

          mapRef.current.addLayer({
            id: 'state-fills',
            type: 'fill',
            source: 'states',
            layout: {},
            paint: {
              'fill-color': '#D3D3D3',
            },
          });

          mapRef.current.addLayer({
            id: 'state-outlines',
            type: 'line',
            source: 'states',
            layout: {},
            paint: {
              'line-color': '#FFFFFF',
              'line-width': 2,
            },
          });

          if (leads) {
            const heatmapData = {
              type: 'FeatureCollection',
              features: leads.leads.map((person) => ({
                type: 'Feature',
                properties: {
                  weight: 1,
                },
                geometry: {
                  type: 'Point',
                  coordinates: person.location_geo.split(',').map(parseFloat).reverse(),
                },
              })),
            };
          
            mapRef.current.addSource('heatmap', {
              type: 'geojson',
              data: heatmapData,
            });
          
            mapRef.current.addLayer({
              id: 'heatmap-layer',
              type: 'heatmap',
              source: 'heatmap',
              paint: {
                'heatmap-weight': [
                  'interpolate',
                  ['linear'],
                  ['get', 'weight'],
                  0, 0,
                  1, 1
                ],
                'heatmap-intensity': [
                  'interpolate',
                  ['linear'],
                  ['zoom'],
                  0, 1,
                  9, 5 
                ],
                'heatmap-color': [
                  'interpolate',
                  ['linear'],
                  ['heatmap-density'],
                  0, 'rgba(33, 102, 172, 0)',    
                  0.2, 'rgba(33, 102, 172, 0.8)',
                  0.4, 'rgba(44, 127, 184, 0.8)',
                  0.6, 'rgba(65, 182, 196, 0.8)',
                  0.8, 'rgba(127, 205, 255, 0.8)',
                  1, 'rgba(193, 255, 255, 0.8)'  
                ],
                'heatmap-radius': [
                  'interpolate',
                  ['linear'],
                  ['zoom'],
                  0, 15,
                  9, 80 
                ],
                'heatmap-opacity': 1
              },
            });
          
            mapRef.current.addLayer({
              id: 'heatmap-point',
              type: 'circle',
              source: 'heatmap',
              minzoom: 7,
              paint: {
                'circle-radius': [
                  'interpolate',
                  ['linear'],
                  ['zoom'],
                  7, 10, 
                  16, 20 
                ],
                'circle-color': 'blue',
                'circle-opacity': [
                  'interpolate',
                  ['linear'],
                  ['zoom'],
                  7, 0,
                  8, 1
                ],
                'circle-stroke-color': 'white',
                'circle-stroke-width': 1,
              },
            });
          }
          
        });
      }
    };

    initializeMap();
  }, [leads.leads]);

  useEffect(() => {
    if (mapRef.current) {
      if (shouldResetMap) {
        if (leads?.geo_id && leads.geo_id !== "USA") {
          const stateFeature = usGeoJson.features.find(feature => feature.properties.GEO_ID === leads.geo_id);
          if (stateFeature && stateFeature.geometry && stateFeature.geometry.coordinates) {
            const bounds = getBoundsFromCoordinates(stateFeature.geometry);
            mapRef.current.fitBounds(bounds, { padding: 100 });
          } else {
            console.warn(`State with GEO_ID '${leads.geo_id}' not found in the geojson data.`);
          }
        } else {
          mapRef.current.easeTo({
            center: [-95, 38],
            zoom: 3.5,
            bearing: 0,
            pitch: 0,
          });
        }
      }
    }
  }, [shouldResetMap, leads, usGeoJson]);
  

  return (
    <>
      <div
        ref={mapContainer}
        style={{
          position: 'absolute',
          top: fromPath === "communities" ? 70 : 157,
          left: fromPath === "communities" ? '22rem' : "10rem",
          right: 0,
          bottom: 0,
          zIndex: 5,
        }}
      >
      </div>
    </>
  );
};

export default UsMap;