import { useState } from 'react';
import StepOne from './stepOne';
import StepTwo from './stepTwo'
import StepThree from './stepThree'
import StepFour from './stepFour'
import {
  ArrowBack,
  ArrowForward,
} from "@mui/icons-material";
import { useDispatch } from 'react-redux';
import { actions } from 'users/ducks'; 
// import { completeOnboarding } from './onboardingSlice';


const Onboarding = () => {
  const dispatch = useDispatch();
  const [step, setStep] = useState(0);

  const nextStep = () => setStep((prevStep) => prevStep + 1);
  const prevStep = () => setStep((prevStep) => (prevStep > 0 ? prevStep - 1 : 0));

  // const completeOnboardingCallback = () => {
  //   dispatch(actions.updateOnboarding()); 
  // };

  // TODO replace these icons with the same ones visually as Figma
  const steps = [
    { content: <StepOne />, title: "Search for people in natural language", image: `${process.env.PUBLIC_URL}/pulse-logo-icon.png` },
    { content: <StepTwo />, title: "Browse and activate pulses to your search", icon: '⚡' },
    { content: <StepThree />, title: "Send auto-drafted personalized outreach when a pulse triggers", icon: '✉️' },
    { content: <StepFour />, title: "Onboarding Complete!", icon: '✅' }
  ];

  return (
    <div className="onboarding flex flex-col justify-between items-center">
      <img
        src={`${process.env.PUBLIC_URL}/pulse-logo.png`}
        alt="pulse icon"
        className="w-32 mt-6 ml-6 self-start"
      />
      <div className="flex flex-col items-center mt-10">
        {steps[step].image ? (
          <img
            src={steps[step].image}
            alt="step icon"
            className="w-20 h-20 rounded-full mb-8"
          />
        ) : (
          <div className="text-6xl mb-8">
            {steps[step].icon}
          </div>
        )}
        <h2 className="mb-10 text-4xl text-center">{steps[step].title}</h2>
        {steps[step].content}
      </div>
      <div className="fixed bottom-0 left-0 right-0 flex justify-center mb-20">
        <div className="flex items-center">
          {step > 0 && (
            <button onClick={prevStep} className="text-gray-500 hover:text-gray-700 mr-20 focus:outline-none">
              <div className='flex'>
                <ArrowBack />
                <p className='ml-2'>Back</p>
              </div>
            </button>
          )}
          {steps.map((_, index) => (
            <div
              key={index}
              className={`h-3 w-3 rounded-full mx-2 cursor-pointer ${index === step ? 'bg-gray-900' : 'bg-gray-300'}`}
              onClick={() => setStep(index)}
            ></div>
          ))}
          {step < steps.length - 1 ? (
            <button onClick={nextStep} className="bg-black text-white px-4 py-2 rounded-full hover:bg-gray-800 ml-20 focus:outline-none">
              <div className='flex'>
                <p className='mr-2'>Next</p>
                <ArrowForward />
              </div>           
            </button>
          ) : (
            <button onClick={() => dispatch(actions.updateOnboarding())} className="bg-black text-white px-4 py-2 rounded-full ml-20 hover:bg-gray-800 focus:outline-none">
              Complete
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Onboarding;
