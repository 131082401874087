import { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { communityActions } from "communities/ducks";
import { Bookmark, BookmarkBorder, Person } from '@mui/icons-material';
import EmptyStateModal from "common/modals/noSearches.js";
import Loader from "../../loader/index.js";
import NavIconAndPage from "common/leads/navIconAndPage";
import { formatNumber } from "common/formatNumber.js";

// smart components fetch data
// dumb components render it
// pass components as props - enhancment
// clean out style tags for animation of hover

const Communities = () => {
  const dispatch = useDispatch();
  const meSelector = useSelector((state) => state.me.meReducer)
  const [selectedCommunityId, setSelectedCommunityId] = useState('');
  const communitySelector = useSelector((state) => state.community);
  const [hoveredCommunityId, setHoveredCommunityId] = useState(null);
  const communityCountsSelector = useSelector((state) => state.community);
  const communityCounts = communityCountsSelector.getCommunityCount.data;

  useEffect(() => {
    if (!meSelector.loading && meSelector.data) {
      if (!communitySelector.getCommunity.fetched && !communitySelector.getCommunity.loading) {
        dispatch(communityActions.getCommunity());
      }

      if (!communitySelector.getCommunity.loading) {
        const communities = communitySelector.getCommunity.data?.communities || [];
        if (communities.length > 0) {
          setSelectedCommunityId(communities[communities.length - 1].community_id);
        }
      }
    }
  }, [dispatch, communitySelector.getCommunity.fetched, communitySelector.getCommunity.loading, meSelector.loading, meSelector.data]);


  const handleCommunityClick = (community_id) => {
    setSelectedCommunityId(community_id);
    dispatch(communityActions.getCommunityById(community_id));
    dispatch(communityActions.getPulseByCommunity(community_id));
  };

  let sumLeadCount = 0;
  let sumTotal = 0;

  if (communityCounts.length > 0) {
    communityCounts.forEach(item => {
      sumLeadCount += item.lead_count;
      if (typeof item.total === 'number') {
        sumTotal += item.total;
      }
    });
  }
  const totalFraction = sumTotal > 0 ? `${formatNumber(sumLeadCount)} / ${formatNumber(sumTotal)}` : sumTotal;

  if (communitySelector.getCommunity.fetched && communitySelector.getCommunity.data?.communities.length === 0) {
    return (
      <div className="flex h-screen">
        <EmptyStateModal show={true} message={"Please run and save a new search to view communities"} />
      </div>
    );
  }

  return (
    <div className="flex h-screen">
      <div className="w-60 bg-white z-10 flex flex-col border-r border-gray-400">
        <div className="flex items-center p-6">
          <div className="flex flex-col items-start w-full">
            <h1 className="text-2xl font-semibold">People</h1>
            <div className="flex items-center justify-between w-full mt-1">
              <p className="text-blue-500 text-md font-semibold mr-2">
                {communityCountsSelector.getCommunityCount.loading ?
                  <div className="relative flex items-center justify-center">
                    <div className="absolute w-6 h-6 bg-blue-500 rounded-full animate-ping"></div>
                    <div className="w-4 h-4 bg-blue-500 rounded-full"></div>
                  </div>
                  : totalFraction}
              </p>
              <Person />
              <p>Unlocked</p>
              {/* <button className="bg-gray-700 text-white text-md px-2 py-1 rounded-lg">
                Upgrade
              </button> */}
            </div>
          </div>
        </div>
        <div className="flex-grow overflow-auto p-2">
          {/* <div className="p-2">
            <Search className="mr-2" />
            <button className="float-right border rounded-lg">
              <span className="p-2">+ new</span>
            </button>
          </div> */}
          {meSelector.loading || communitySelector.getCommunity.loading || communityCountsSelector.getCommunityCount.loading ? (
            <div className="flex flex-col items-center justify-center h-screen">
              <div className="flex flex-col items-center">
                <Loader />
                <p className="mr-4 mt-10 text-lg">Loading your communities...</p>
              </div>
            </div>
          ) : (
            communitySelector.getCommunity.data?.communities
              ?.slice()
              .reverse()
              .map((community) => (
                <div key={community.community_id}>
                  <ul>
                    <li
                      className={`relative flex items-center p-2 mb-2 text-gray-700 bg-gray-50 rounded-lg focus:outline-none cursor-pointer ${selectedCommunityId === community.community_id ? 'bg-blue-200' : ''
                        }`}
                      onMouseEnter={() => setHoveredCommunityId(community.community_id)}
                      onMouseLeave={() => setHoveredCommunityId(null)}
                      style={{
                        transition: 'transform 0.3s ease',
                        transform: hoveredCommunityId === community.community_id ? 'translateY(-10px)' : 'translateY(0)',
                        zIndex: hoveredCommunityId === community.community_id ? '10' : 'auto',
                      }}
                    >
                      <button
                        className="flex items-center justify-center focus:outline-none w-full relative"
                        onClick={() => handleCommunityClick(community.community_id)}
                      >
                        {selectedCommunityId === community.community_id ? (
                          <Bookmark className="mr-2 text-blue-500" />
                        ) : (
                          <BookmarkBorder className="mr-2" />
                        )}
                        <span className="flex-1 overflow-hidden text-ellipsis whitespace-nowrap">
                          {community.objective?.substring(0, 20) + (community.objective?.length > 20 ? '...' : '')}
                        </span>
                        {hoveredCommunityId === community.community_id && (
                          <div className="absolute left-0 right-0 bg-blue-100 text-gray-800 p-2 rounded-lg shadow-lg z-10">
                            {community.objective}
                          </div>
                        )}
                      </button>
                    </li>
                  </ul>
                </div>
              ))
          )}
        </div>
      </div>
      <div className="flex-1 mt-4 overflow-y-auto">
        {meSelector.loading || communitySelector.getCommunity.loading || communitySelector.getCommunityById.loading || communitySelector.getPulseByCommunity.loading ? (
          <div className="flex flex-col items-center justify-center h-screen">
            <div className="flex flex-col items-center">
              <Loader />
              <p className="mr-4 mt-10 text-lg">Loading communities content...</p>
            </div>
          </div>
        ) : (
          <div>
            <NavIconAndPage leads={communitySelector.getCommunityById.data} fromPath={"communities"} />
          </div>
        )}
      </div>
    </div >
  );
};

export default Communities;