import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import DraftCards from './draftCards';
import { pulseActions } from './ducks';
import EmptyStateModal from 'common/modals/noSearches';
import Loader from 'loader';
import { formatNumber } from 'common/formatNumber';
// import mockPulses from './mockPulses.json'

// import Quill from 'quill';
// import 'quill/dist/quill.snow.css';

// TODO: Handle the activated pulses data from BE properly in cards/ confirm structure

const PulseCards = () => {
	const dispatch = useDispatch();
	const meSelector = useSelector((state) => state.me.meReducer)
	const pulseSelector = useSelector((state) => state.pulses);
	const communityCountsSelector = useSelector((state) => state.community);
	const communityCounts = communityCountsSelector.getCommunityCount.data;
	const pulseNotifications = pulseSelector.getPulseNotifications.data;
	const activatedPulseTypes = pulseSelector.getActivatedPulses.data;

	useEffect(() => {
		if (!meSelector.loading && meSelector.data) {
			if (!pulseSelector.getActivatedPulses.fetched && !pulseSelector.getActivatedPulses.loading) {
				dispatch(pulseActions.getActivatedPulseTypes());
			}

			if (!pulseSelector.getPulseNotifications.fetched && !pulseSelector.getPulseNotifications.loading) {
				dispatch(pulseActions.getPulseNotifications());
			}
		}
	}, [
		dispatch,
		pulseSelector.getActivatedPulses.fetched,
		pulseSelector.getActivatedPulses.loading,
		pulseSelector.getPulseNotifications.fetched,
		pulseSelector.getPulseNotifications.loading,
		meSelector.loading,
		meSelector.data
	]);

	let sumLeadCount = 0;
	let sumTotal = 0;

	if (communityCounts.length > 0) {
		communityCounts.forEach(item => {
			sumLeadCount += item.lead_count;
			if (typeof item.total === 'number') {
				sumTotal += item.total;
			}
		});
	}

	if (activatedPulseTypes.pulses?.length === 0 && !pulseSelector.getActivatedPulses.loading && !communityCountsSelector.getCommunityCount.loading) {
		return (
			<div className="flex h-screen">
				<EmptyStateModal show={true} message={'Please attach pulses in your saved tab to begin pulsing your prospects'} />
			</div>
		);
	}

	if (pulseSelector.getPulseNotifications.loading || pulseSelector.getActivatedPulses.loading || communityCountsSelector.getCommunity.loading) {
		return (
			<div className="flex items-center justify-center h-screen">
				<span className="mr-4 text-lg">Loading your Pulses...</span>
				<div className="relative">
					<Loader />
				</div>
			</div>
		);
	}

	if (!pulseSelector.getActivatedPulses.loading && activatedPulseTypes && activatedPulseTypes.length === 0) {
		return (
			<div className="flex h-screen">
				<div className="absolute inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50">
					<div className="bg-white p-6 rounded-lg shadow-lg text-center max-w-md">
						<p>Attach pulses to your communities to activate notifications, email drafts, and more on {formatNumber(sumTotal)} potential customers.</p>
						<Link to="/communities" className="mt-4 inline-block bg-blue-500 text-white p-2 rounded-lg">
							Attach Pulses
						</Link>
					</div>
				</div>
			</div>
		);
	};

	return (
		<div className="flex h-screen">
			<div className="flex-grow overflow-auto">
				{pulseSelector.getPulseNotifications.fetched &&
					pulseNotifications &&
					pulseSelector.getPulseNotifications.data.pulse_notifications?.filter(notification => !notification.acknowledged).length > 0 ? (
					<DraftCards notifications={pulseSelector.getPulseNotifications.data.pulse_notifications?.filter(notification => !notification.acknowledged)} />
				) : (
					<div className="flex flex-col items-center justify-center h-screen">
						{!pulseSelector.getActivatedPulses.loading && activatedPulseTypes && activatedPulseTypes.pulses.length > 0 &&
							<div className="flex flex-col items-center">
								<Loader />
								<h3 className="font-semibold text-4xl mt-20">
									Pulsing <span className="text-blue-500">{formatNumber(sumLeadCount)}</span> people
								</h3>
								<p className="mt-2">
									Personalized drafted emails will show here when a Pulse hits
								</p>
								<p>
									Please check back later
								</p>
							</div>
						}
					</div>
				)
				}
			</div>
		</div>
	)
}

export default PulseCards

// {mockPulses ? (
//  	<DraftCards notifications={mockPulses} />
//  ) : (