import { takeLatest, put, select } from "redux-saga/effects";
import { actions, actionTypes } from "./ducks";
import api from "../api";
import { selectAccessToken } from "authSelector"; 

export default [
  takeLatest(actionTypes.GET_ME_DETAILS, onGetMe),
  takeLatest(actionTypes.UPDATE_ONBOARDING_STATUS, onUpdateOnboarded),
  takeLatest(actionTypes.SEND_EMAIL, onSendEmail)
];

function* onGetMe() {
  try {
    const accessToken = yield select(selectAccessToken);
    const response = yield api.get(
      'https://sonic.pulseco.ai/users/me',
      // 'http://127.0.0.1:8000/users/me',
      {
        Authorization: `Bearer ${accessToken}`,
      },
    );
    yield put(actions.getMeSuccess(response.data));
  } catch (error) {
    const { response } = error;
    yield put(actions.getMeFailure(response.data));
  }
}

function* onUpdateOnboarded() {
  try {
    const accessToken = yield select(selectAccessToken);
    const response = yield api.put(
      'https://sonic.pulseco.ai/users/onboarded',
      // 'http://127.0.0.1:8000/users/onboarded',
      {
        Authorization: `Bearer ${accessToken}`,
      },
    );
    yield put(actions.updateOnboardingSuccess(response.data));
    yield put(actions.getMe())
  } catch (error) {
    const { response } = error;
    yield put(actions.updateOnboardingFailure(response.data));
  }
}

function* onSendEmail(action) {
  try {
    const accessToken = yield select(selectAccessToken);
    const { sender, to, subject, body } = action.payload
    const response = yield api.post(
      'https://sonic.pulseco.ai/users/emails',
      // 'http://127.0.0.1:8000/users/emails',
      {
        Authorization: `Bearer ${accessToken}`,
      },
      {
        sender: sender,
        to: to,
        subject: subject,
        body: body,
      },
    );
    yield put(actions.sendEmailsSuccess(response.data));
  } catch (error) {
    const { response } = error;
    yield put(actions.sendEmailsFailure(response.data));
  }
}