import { useAuth0 } from '@auth0/auth0-react';
import { useSelector } from 'react-redux';
import { EmailOutlined, Google, Person } from '@mui/icons-material';
import useProfilePicture from 'common/useProfilePicture';
import Spinner from 'common/spinner';

// TODO: Correct the redirect from google login
// Allow user to signout and add a different mailbox

const Profile = () => {
  const redirectUri = process.env.REACT_APP_SEND_URI_PROD;
  const { logout } = useAuth0();
  const profilePic = useProfilePicture();
  const userDetails = useSelector((state) => state.me.meReducer.data)
  const meLoading = useSelector((state) => state.me.meReducer.loading)

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-50">
      <div className="w-full max-w-4xl p-6 bg-white rounded-xl space-y-6">
        <div className="flex space-x-6">
          <div className="w-1/2 p-4 border rounded-xl shadow-lg">
            <div className="text-2xl font-bold mb-4">Profile</div>
            <div className="flex items-center">  {meLoading ? (
              <Spinner />
            ) : (
              profilePic ? (
                <img
                  src={profilePic}
                  referrerPolicy="no-referrer"
                  alt="user profile picture"
                  className="rounded-full w-12 h-12 mr-3" />
              ) : (
                <Person className="rounded-full w-20 h-20 mt-2 mr-2" />
              )
            )}
              <div>{userDetails ? userDetails.name : ''}</div>
            </div>
            <button className="p-2 rounded-lg border mt-4" onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>Logout</button>
          </div>
          <div className="w-1/2 p-4 border rounded-xl shadow-lg">
            <div className="text-2xl font-bold mb-2">Billing</div>
                <p className='mt-1 mb-1'>*Monthly per stripe checkout details</p>
                <p className='mt-1 mb-1'>*Additional accound details coming soon</p>
                <p className='text-sm font-gray-500 mt-1'>*For now please email hello@puseco.ai for details or refer to signed document for service</p>
          </div>
        </div>
        <div className="flex space-x-6">
          <div className="w-1/2 p-4 border rounded-lg shadow-lg">
            <div className="text-2xl font-bold">Connect Lists</div>
            <div className="flex justify-center items-center h-32 border border-dashed border-gray-300 rounded-lg">
              {/* <div className="text-center">
                <svg
                  className="w-12 h-12 mx-auto mb-4 text-gray-400"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 4v16m8-8H4"
                  />
                </svg>
                <div>Choose Connection</div>
              </div> */}
              <p>Coming soon!!</p>
            </div>
          </div>

          <div className="w-1/2 p-4 border rounded-lg shadow-lg">
            <div className="text-2xl font-bold">Send outreach from</div>
            <div className="space-y-2">
              {userDetails && userDetails.user_info ? (
                userDetails.user_info.meta?.credentials?.refresh_token ? (
                  <div className='flex mt-4'>
                    <EmailOutlined className='mr-2' />
                    <p>{userDetails.user_info.user_id}</p>
                  </div>
                ) : (
                  <div>
                    <p className='font-semibold text-sm'>Note: Please select the same email you registered with</p>
                    <a
                      href={`https://accounts.google.com/o/oauth2/auth?response_type=code&client_id=306269071464-nl9fsl63a4el8qh1sdh9m3f3lrhk7d08.apps.googleusercontent.com&redirect_uri=${redirectUri}&scope=https://www.googleapis.com/auth/gmail.send+https://www.googleapis.com/auth/userinfo.email+https://www.googleapis.com/auth/userinfo.profile&state=tbb3cU7uAdS0BXVmnWQKCZ1L4Xr0fl&access_type=offline`}
                      className="flex items-center mt-6 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                    >
                      <Google className="mr-2" />
                      Connect an email to send pulses
                    </a>
                  </div>
                )
              ) : (
                <div>Loading...</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
